export const itemData = [
    
 {
        order : 5,
        id:'5',titulo:'4 DORMITORIOS EN SUITE * KENNEDY□ALDERETE * AÑO 2011',comuna:'Las Condes',tipo:'depto',m2:'202',precio:'17990',terraza:'50',m2_totales:'252',dormitorios:'4',banhos:'5',estacionamientos:'2',bodega:'1',piso:'19',orientacion:'SO/NP',year:'2011',GComunes:'610000',Contribuciones:'890000',
        otros:'Loggia S.Estar',calefaccion:'piso radiante',aguacaliente:'central'
        ,descripcion:`2 Orientaciones Suroriente Hnos. Cabot / Norponiente Kennedy
        □        Living con vista panorámica a la cordillera
        □        Con Sala de Estar y Loggia
        □        Espacios amplios, muy bien iluminado
        □        LAS CONDES Sector Cabot / Alderete / Kennedy
        □        2 estacionamientos y 1 bodega
        □        EComunes: piscina / sala de eventos / gimnasio / lavandería `
        ,
        fotos: [
            { img: '/out/P5/01.webp' },
            { img: '/out/P5/02.webp' },
            { img: '/out/P5/03.webp' },
            { img: '/out/P5/04.webp' },
            { img: '/out/P5/05.webp' },
            { img: '/out/P5/06.webp' },
            { img: '/out/P5/07.webp' },
            { img: '/out/P5/08.webp' },
            { img: '/out/P5/09.webp' },
            { img: '/out/P5/10.webp' },
            { img: '/out/P5/11.webp' },
            { img: '/out/P5/12.webp' },
            { img: '/out/P5/13.webp' },
            { img: '/out/P5/14.webp' },
            { img: '/out/P5/15.webp' },
            { img: '/out/P5/16.webp' }

        ]
    }

    
    ,
    
    {
        order : 0,
        id:'11'
        ,titulo:'3+1 DORMITORIOS PANORÁMICOS * SECTOR LAS LILAS'
        ,comuna:'Providencia'
        ,tipo:'depto',m2:'253'
        ,precio:'19000'
        ,terraza:'20'
        ,m2_totales:'273'
        ,dormitorios:'4'
        ,banhos:'4'
        ,estacionamientos:'2'
        ,bodega:'1'
        ,piso:'16'
        ,orientacion:'TODAS'
        ,year:'1979'
        ,GComunes:'651000'
        ,Contribuciones:'780000'
        ,otros:'S.Estar Loggia'
        ,calefaccion:'radiadores agua'
        ,aguacaliente:'central a gas'
        ,descripcion:`19.000 UF Entrada directa + ascensor servicio
        □	Amplísimos Living-Comedor-Sala de estar
        □	Gran arquitectura años 80, vistas totales
        □	Piso industrial de madera
        □	Cocina y baños de origen`        
        ,fotos: [
            { img: '/out/P11/01.webp' },
            { img: '/out/P11/02.webp' },
            { img: '/out/P11/03.webp' },
            { img: '/out/P11/04.webp' },
            { img: '/out/P11/05.webp' },
            { img: '/out/P11/06.webp' },
            { img: '/out/P11/07.webp' },
            { img: '/out/P11/08.webp' },
            { img: '/out/P11/09.webp' },
            { img: '/out/P11/10.webp' },
            { img: '/out/P11/11.webp' },
            { img: '/out/P11/12.webp' },
            { img: '/out/P11/13.webp' },
            { img: '/out/P11/14.webp' },
            { img: '/out/P11/15.webp' },
            { img: '/out/P11/16.webp' },
            { img: '/out/P11/17.webp' },
            { img: '/out/P11/18.webp' },
            { img: '/out/P11/19.webp' },
            { img: '/out/P11/20.webp' }

        ]

        
    }
    ,
    {
        order : -1,
        id:'12',
        titulo:'GRAN UBICACIÓN Y ARQUITECTURA_60m2 en Av. El Golf '
        ,comuna:'Las Condes'
        ,tipo:'depto'
        ,m2:'60'
        ,precio:'7800'
        ,terraza:''
        ,m2_totales:'60'
        ,dormitorios:'1 o 2'
        ,banhos:'1 o 2'
        ,estacionamientos:'1'
        ,bodega:'1'
        ,piso:'7'
        ,orientacion:'Oriente'
        ,year:'2010'
        ,GComunes:'230000'
        ,Contribuciones:'160'
        
        ,calefaccion:'piso radiante'
        ,aguacaliente:''
        ,descripcion:`Todo a Oriente 
        □   Moderno y de diseño
        □   1 o 2 dormitorios
        □   arquitecto Borja Huidobro
        □   Edificio Premium`       
        ,fotos: [
            { img: '/out/P12/01.webp' },
            { img: '/out/P12/02.webp' },
            { img: '/out/P12/03.webp' },
            { img: '/out/P12/04.webp' },
            { img: '/out/P12/05.webp' },
            { img: '/out/P12/06.webp' },
            { img: '/out/P12/07.webp' },
            { img: '/out/P12/08.webp' },
            { img: '/out/P12/09.webp' },
            { img: '/out/P12/10.webp' },
            { img: '/out/P12/11.webp' }

        ]

        
    }
   
   ,
   {
    order:-3,
    id:'14'
    ,titulo:'EXCELENTES ESPACIOS sector av. El Bosque * PROVIDENCIA  '
    ,comuna:'Providencia'
    ,tipo:'depto'
    ,m2:'129'
    ,precio:'9990'
    ,terraza:'8'
    ,m2_totales:'137'
    ,dormitorios:'3'
    ,banhos:'3'
    ,estacionamientos:'2'
    ,bodega:'1'
    ,piso:'3'
    ,orientacion:'S-O-P'
    ,year:'1993'
    ,GComunes:'220000'
    ,Contribuciones:'320000'
    ,otros:'Loggia'
    ,calefaccion:'eléctrica'
    ,aguacaliente:'calefón'
    ,descripcion:`Cocina remodelada, nueva
    □ Impecable estado, espacioso departamento
    □ Privilegiada iluminación natural
    □ Moderna cocina semi-abierta
    □ 3 Dormitorios independientes, todos con baño
    □ Ubicación y entorno formidables
    □ 2 estacionamientos y 1 bodega`
    ,fotos: [
        { img: '/out/P14/01.webp' },
        { img: '/out/P14/02.webp' },
        { img: '/out/P14/03.webp' },
        { img: '/out/P14/04.webp' },
        { img: '/out/P14/05.webp' },
        { img: '/out/P14/06.webp' },
        { img: '/out/P14/07.webp' },
        { img: '/out/P14/08.webp' },
        { img: '/out/P14/09.webp' },
        { img: '/out/P14/10.webp' },
        { img: '/out/P14/11.webp' },
        { img: '/out/P14/12.webp' },
        { img: '/out/P14/13.webp' },
        { img: '/out/P14/14.webp' },
        { img: '/out/P14/15.webp' },
        { img: '/out/P14/16.webp' },
        { img: '/out/P14/17.webp' },
        { img: '/out/P14/18.webp' },
        { img: '/out/P14/19.webp' },
        { img: '/out/P14/20.webp' },
        { img: '/out/P14/21.webp' },
        { img: '/out/P14/22.webp' },
        { img: '/out/P14/23.webp' },
        { img: '/out/P14/24.webp' }

    ]

   }
   ,
   {
    order:-4,
    id:'15'
    ,titulo:'COMO NUEVO * oportunidad en barrio Las Lilas- Providencia '
    ,comuna:'Providencia'
    ,tipo:'depto'
    ,m2:'104'
    ,precio:'7990'
    ,terraza:'14'
    ,m2_totales:'118'
    ,dormitorios:'4'
    ,banhos:'3'
    ,estacionamientos:'1'
    ,bodega:'1'
    ,piso:'9'
    ,orientacion:'Oriente y Sur'
    ,year:'1982-Remod.'
    ,GComunes:'163000'
    ,Contribuciones:'180000'
    ,otros:'Loggia'
    ,calefaccion:'radiadores    '
    ,aguacaliente:'calefón'
    ,descripcion:`Excelentes vistas 
    □ 2 terrazas
    □ 3+1 dormitorios amplios
    □ Living a Oriente
    □ Dormitorios a Suroriente
    □ Remodelación reciente, casi a estrenar `
    ,fotos: [
    { img: '/out/P15/01.webp' },
    { img: '/out/P15/02.webp' },
    { img: '/out/P15/03.webp' },
    { img: '/out/P15/04.webp' },
    { img: '/out/P15/05.webp' },
    { img: '/out/P15/06.webp' },
    { img: '/out/P15/07.webp' },
    { img: '/out/P15/08.webp' },
    { img: '/out/P15/09.webp' },
    { img: '/out/P15/10.webp' },
    { img: '/out/P15/11.webp' },
    { img: '/out/P15/12.webp' },
    { img: '/out/P15/13.webp' },
    { img: '/out/P15/14.webp' },
]

   }
    

,
{
    order:-12,    
    id:'20',titulo:'DEL 2.021 @TOBALABA/BILBAO',comuna:'Providencia',tipo:'depto',m2:'65',precio:'7113',terraza:'8',m2_totales:'73',dormitorios:'2',banhos:'2'
    ,estacionamientos:'1',bodega:'1',piso:'2',orientacion:'NP y NO',year:'2021',GComunes:'265000',Contribuciones:'155000'
    ,otros:'',calefaccion:'radiadores    elécricos',aguacaliente:'central'
    ,descripcion:`□        7.113 UF    
    □        65 m2 útiles + 8 m2 terraza
    □        2 dormitorios GRANDES
    □        Edificio moderno y nuevo
    □        PISO 2. Norponiente y Nororiente
    □        MUY RENTABLE INVERSIÓN 
    □        Conectividad <M> Bilbao`
    ,fotos:[
        { img: '/out/P20/01.webp' },
        { img: '/out/P20/02.webp' },
        { img: '/out/P20/03.webp' },
        { img: '/out/P20/04.webp' },
        { img: '/out/P20/05.webp' },
        { img: '/out/P20/06.webp' },
        { img: '/out/P20/07.webp' },
        { img: '/out/P20/08.webp' },
        { img: '/out/P20/09.webp' },
        { img: '/out/P20/10.webp' }
    ]

}
,

{
    order:-13    
    ,id:'23'
    ,titulo:'IMPECABLE EN BILBAO/TOBALABA * 3D/3B '
    ,comuna:'Providencia'
    ,tipo:'depto'
    ,m2:'122'
    ,precio:'12052'
    ,terraza:'34'
    ,m2_totales:'156'
    ,dormitorios:'3'
    ,banhos:'3'
    ,estacionamientos:'1'
    ,bodega:'1'
    ,piso:'5'
    ,orientacion:''
    ,year:'2022'
    ,GComunes:'345000'
    ,Contribuciones:'190000'
    ,otros:'Sala Estar Loggia 2WClosets'
    ,calefaccion:'radiadores'
    ,aguacaliente:'central'
    ,descripcion:`□ 12.052 UF
    □ 122 m2 útiles + 34 m2 2 terrazas 
    □ Más sala de Estar + Loggia + 2 Walking Closets
    □ DEL 2022
    □ Norponiente y Nororiente
    □ BUENAS VISTAS`
    ,fotos:  [
        { img: '/out/P23/01.webp' },
        { img: '/out/P23/02.webp' },
        { img: '/out/P23/03.webp' },
        { img: '/out/P23/04.webp' },
        { img: '/out/P23/05.webp' },
        { img: '/out/P23/06.webp' },
        { img: '/out/P23/07.webp' },
        { img: '/out/P23/08.webp' },
        { img: '/out/P23/09.webp' },
        { img: '/out/P23/10.webp' },
        { img: '/out/P23/11.webp' },
        { img: '/out/P23/12.webp' },
        { img: '/out/P23/13.webp' },
        { img: '/out/P23/14.webp' },
        { img: '/out/P23/15.webp' },
        { img: '/out/P23/16.webp' },
        { img: '/out/P23/17.webp' },
        { img: '/out/P23/18.webp' },
        { img: '/out/P23/19.webp' },
        { img: '/out/P23/20.webp' },
        { img: '/out/P23/21.webp' }        
    ]
}   
,

{order:-15 ,  
    id:'25',titulo:'MÁS QUE 1 DORMITORIO- EXCELENTE INVERSIÓN 4.690 UF'
    ,comuna:'Las Condes'
    ,tipo:'depto'
    ,m2:'41',precio:'4690',terraza:'2',m2_totales:'43',dormitorios:'1+escritorio',banhos:'1',estacionamientos:'1',bodega:'0',piso:'2',orientacion:'surponiente',year:'1993',GComunes:'100000',Contribuciones:'45000',otros:'escritorio',calefaccion:'radiadores',aguacaliente:'central'
    ,descripcion:`□ 3 camas para arriendo Airbnb 
    □ Recién remodelado y nuevo
    □ 2 dormitorios (2º pequeño)
    □ Oportunidad en Las Condes
    □ A POCOS pasos de <M> E. Militar
    □ Compra muy RENTABLE
    □ Piso 2 hacia atrás
    □ 41m2 útiles+2m2 terraza`
    ,fotos:  [
        { img: '/out/P25/01.webp' },
        { img: '/out/P25/02.webp' },
        { img: '/out/P25/03.webp' },
        { img: '/out/P25/04.webp' },
        { img: '/out/P25/05.webp' },
        { img: '/out/P25/06.webp' },
        { img: '/out/P25/07.webp' },
        { img: '/out/P25/08.webp' },
        { img: '/out/P25/09.webp' },
        { img: '/out/P25/10.webp' }
    ]       
}     
,
{
    order:-16 ,  
    id:'26',titulo:'DEL 2016 EN VITACURA * 3d/3b 150M2 TOTALES',comuna:'Vitacura',tipo:'depto',m2:'125',precio:'14300',terraza:'25',m2_totales:'150',dormitorios:'3',banhos:'3',estacionamientos:'2',bodega:'1',piso:'3',orientacion:'nororiente',year:'2018',GComunes:'400000',Contribuciones:'394000'
    ,otros:'com. diario    Loggia    Pallier'
    ,calefaccion:'central    losa radiante'
    ,aguacaliente:'central'
    ,descripcion:`□ Zona Alianza Francesa / Av. Vitacura
    □	Sector Clínica Alemana / Estadio Manquehue
    □	Ubicación Colegio san Pedro Nolasco / frente Parque Arauco
    □	Excelentes terminaciones, De Diseño
    □	Piso 3. Gran terraza con vista verde 
    □	Cocina con comedor de diario y Loggia
    □	14.300 UF`    
    ,fotos:  [
        { img: '/out/P26/01.webp' },
 { img: '/out/P26/02.webp' },
 { img: '/out/P26/03.webp' },
 { img: '/out/P26/04.webp' },
 { img: '/out/P26/05.webp' },
 { img: '/out/P26/06.webp' },
 { img: '/out/P26/07.webp' },
 { img: '/out/P26/08.webp' },
 { img: '/out/P26/09.webp' },
 { img: '/out/P26/10.webp' },
 { img: '/out/P26/11.webp' },
 { img: '/out/P26/12.webp' },
 { img: '/out/P26/13.webp' },
 { img: '/out/P26/14.webp' },
 { img: '/out/P26/15.webp' },
 { img: '/out/P26/16.webp' },
 { img: '/out/P26/18.webp' },
 { img: '/out/P26/19.webp' },
 { img: '/out/P26/20.webp' },
 { img: '/out/P26/21.webp' },
 { img: '/out/P26/22.webp' }
    ]       
}   
,
{
    order:-17 ,  
    id:'27',titulo:'CON JARDÍN Y GRAN SALA EN PISO 1 * 3d/4b EN VITACURA',comuna:'Vitacura',tipo:'depto',m2:'170',precio:'21500',terraza:'130',m2_totales:'300',dormitorios:'3',banhos:'4',estacionamientos:'2',bodega:'1',piso:'1+2',orientacion:'SP/SO',year:'2018',GComunes:'450000',Contribuciones:'400000'
    ,otros:'PlayRoom JARDÍN Loggia Pallier'
    ,calefaccion:'central losa radiante'
    ,aguacaliente:'central'
    ,descripcion:`□	Departamento 21.500 UF 
    □	con Jardín vivir como en una casa
    □	170 m2 útiles+ 100 m2 jardín+ 30 m2 terraza
    □	Moderno, excelente arquitectura 
    □	3 terrazas en los 2 niveles
    □	Play Room-Sala de Estar&bar en Piso1
    □	Cercano a Alianza Francesa y Clínica Alemana`
    ,fotos:  [
        { img: '/out/P27/01.webp' },
        { img: '/out/P27/02.webp' },
        { img: '/out/P27/03.webp' },
        { img: '/out/P27/04.webp' },
        { img: '/out/P27/05.webp' },
        { img: '/out/P27/06.webp' },
        { img: '/out/P27/07.webp' },
        { img: '/out/P27/08.webp' },
        
        { img: '/out/P27/10.webp' },
        
        { img: '/out/P27/12.webp' },
        { img: '/out/P27/13.webp' },
        { img: '/out/P27/14.webp' },
        { img: '/out/P27/15.webp' },
        { img: '/out/P27/16.webp' },
        { img: '/out/P27/17.webp' },
        { img: '/out/P27/18.webp' },
        { img: '/out/P27/19.webp' },
        { img: '/out/P27/20.webp' },
        { img: '/out/P27/21.webp' }
    ]       
}   
    
,
{
    order:-18 ,  
    id:'28',titulo:'SECTOR VATICANO / ALCÁNTARA – VISTA CORDILLERA',comuna:'Las Condes',tipo:'depto',m2:'150',precio:'13500',terraza:'10',m2_totales:'160',dormitorios:'4',banhos:'4',estacionamientos:'2',bodega:'1',piso:'11',orientacion:'Oriente',year:'2004',GComunes:'230000',Contribuciones:'370000'
    ,otros:'Loggia dor. servicio',calefaccion:'caldera individual losa radiante',aguacaliente:'caldera individual '
    ,descripcion:`□ Edificio noble y clásico / muchos espacios comunes
    □        Piso 11 Vistas Panorámicas
    □        Excelente estado / espacios amplios
    □        Entre <M> Bilbao / <M> Colón
    □        Orientación principal Oriente, también Norte y Sur`
    ,fotos:  [
        { img: '/out/P28/01.webp' },
 { img: '/out/P28/02.webp' },
 
 { img: '/out/P28/04.webp' },
 { img: '/out/P28/05.webp' },
 { img: '/out/P28/06.webp' },
 { img: '/out/P28/07.webp' },
 
 { img: '/out/P28/09.webp' },
 { img: '/out/P28/10.webp' },
 { img: '/out/P28/11.webp' },
 { img: '/out/P28/12.webp' },
 { img: '/out/P28/13.webp' },
 { img: '/out/P28/14.webp' },
 { img: '/out/P28/15.webp' },
 { img: '/out/P28/16.webp' },
 { img: '/out/P28/17.webp' },
 { img: '/out/P28/18.webp' },
 { img: '/out/P28/19.webp' }
    ]       
}   
,
{
    order:-19 ,  
    id:'29',titulo:'AMPLIO- DE 1 DORMITORIO <M> E.MILITAR / MANQUEHUE',comuna:'Las Condes',tipo:'depto',m2:'52',precio:'4900',terraza:'9',m2_totales:'61',dormitorios:'1',banhos:'1',estacionamientos:'1',bodega:'1',piso:'12',orientacion:'suroriente',year:'2002',GComunes:'143000',Contribuciones:'70000'
    ,otros:'Loggia'
    ,calefaccion:'central losa radiante'
    ,aguacaliente:'central'
,descripcion:`□	61 m2 totales□ amplio depto. De 1 dormitorio
□	2 terrazas, la principal es espaciosa
□	1 dormitorio ampliado y GRANDE 
□	¡Con Loggia y cocina INDEPENDIENTES!
□	PISO 12. SURORIENTE
□	Excelente en Las Condes cerca Apoquindo
□	Piso flotante y sanitario NUEVOS`
,fotos:  [
    { img: '/out/P29/01.webp' },
    { img: '/out/P29/02.webp' },
    { img: '/out/P29/03.webp' },
    { img: '/out/P29/04.webp' },
    { img: '/out/P29/05.webp' },
    { img: '/out/P29/06.webp' },
    { img: '/out/P29/07.webp' },
    { img: '/out/P29/08.webp' },
    { img: '/out/P29/09.webp' },
    { img: '/out/P29/10.webp' },
    { img: '/out/P29/11.webp' },
    { img: '/out/P29/12.webp' },
    { img: '/out/P29/13.webp' },
    { img: '/out/P29/14.webp' },
    { img: '/out/P29/15.webp' }
        
]       
}   
]